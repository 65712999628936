import React from 'react'
import "normalize.css"
import { graphql, useStaticQuery, Link } from 'gatsby'
import indexHeaderStyles from './sass/index-header.module.scss'
import { Link as LinkScroll } from 'react-scroll'

const IndexHeader = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    subtitle
                    introduction
                }
            }
        }
    `)
    return(
        <div className={indexHeaderStyles.navigation}>
            <input type="checkbox" className={indexHeaderStyles.navigationCheckbox} id="navi-toggle"></input>
            <label htmlFor="navi-toggle" className={indexHeaderStyles.navigationButton}>
                <span className={indexHeaderStyles.navigationIcon}></span> 
            </label>
            <div className={indexHeaderStyles.navigationBackground}>&nbsp;</div>
            <nav className={indexHeaderStyles.navigationNav}>
                <ul className={indexHeaderStyles.navigationList}>
                    <li className={indexHeaderStyles.navigationItem}><Link to={"/blog"} className={indexHeaderStyles.navigationLink}>Blog</Link></li>
                    <li className={indexHeaderStyles.navigationItem}><Link to={"/about"} className={indexHeaderStyles.navigationLink}>About me</Link></li>
                    <li className={indexHeaderStyles.navigationItem}><Link to={"/contact"} className={indexHeaderStyles.navigationLink}>Contact</Link></li>
                </ul>
            </nav>
            <div id="header" className={indexHeaderStyles.homeHeader}>
                <div className={indexHeaderStyles.headingBox}>
                    <h1 className={indexHeaderStyles.headingPrimary}>
                        <span className={indexHeaderStyles.headingPrimaryMain}>{data.site.siteMetadata.introduction}</span>
                        <span className={indexHeaderStyles.headingPrimarySub}>{data.site.siteMetadata.subtitle}</span> 
                    </h1>
                    
                    <LinkScroll  
                        activeClass="active"
                        to="projects"
                        spy={true}
                        hashSpy={true}
                        smooth={true}
                        offset={0}
                        duration={2000}
                        delay={50}
                        isDynamic={true}
                        className={[indexHeaderStyles.btn, indexHeaderStyles.btnWhite, indexHeaderStyles.btnAnimated].join(' ')}>Discover my projects!</LinkScroll>           
                </div>
            </div> 
        </div>
    )
}

export default IndexHeader